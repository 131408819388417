import React from "react";

class TextField extends React.Component {
render() {
    const verify = (event) => {
        this.props.verify(event.target.value)
    }
    return (
        <div className="form-group" onChange={verify}>
            <label htmlFor="exampleInputEmail1">Input Text</label>
            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                   placeholder="Enter Text"></input>
            <small id="emailHelp" className="form-text text-muted">Sending over to API for verification</small>
        </div>
    )
}
}
export default TextField;