import Row from "./Row";
import React from "react";
import GameOver from "./GameOver";
import audio from './error.mp3';



class Board extends React.Component {



    default_keyb = {
        "q":-1,
        "w":-1,
        "e":-1,
        "r":-1,
        "t":-1,
        "y":-1,
        "u":-1,
        "i":-1,
        "o":-1,
        "p":-1,
        "a":-1,
        "s":-1,
        "d":-1,
        "f":-1,
        "g":-1,
        "h":-1,
        "j":-1,
        "k":-1,
        "l":-1,
        "z":-1,
        "x":-1,
        "c":-1,
        "v":-1,
        "b":-1,
        "n":-1,
        "m":-1
    }

    master_guess_list = {}

    count_keyb = {
        "q":0,
        "w":0,
        "e":0,
        "r":0,
        "t":0,
        "y":0,
        "u":0,
        "i":0,
        "o":0,
        "p":0,
        "a":0,
        "s":0,
        "d":0,
        "f":0,
        "g":0,
        "h":0,
        "j":0,
        "k":0,
        "l":0,
        "z":0,
        "x":0,
        "c":0,
        "v":0,
        "b":0,
        "n":0,
        "m":0
    }
    game_over = "hide";

    number_of_guesses = {
        1:0,
        2:0,
        3:0,
        4:0,
        5:0,
        6:0,
    }

    audio = new Audio("error.mp3")

    yoshi = new Audio("yoshi.mp3")


    mario = new Audio("mario.mp3")

    luigi = new Audio("luigi.mp3")

    wario = new Audio("wario.mp3")

    toad = new Audio("toad.mp3")

    rows = []
    row_number = 0

    letter_number = 0

    animating = false



    word = ""

    correct_word = false

    word_exists = false

    row_words = ["", "", "", "", ""]

    //letter_number = 0;

    validation_array = []

    final_word = ""

    results = []

    result_array = [[]]

    wrong = <p>&#8203;</p>
    right =<div/>

    constructor() {



        super();
        this.componentCleanup = this.componentCleanup.bind(this);
        this.row_number = 0;


        for (let i = 0; i <= 5; i++) {
            this.rows.push(<div><Row validation_array = {[]} final_word = {""} latest_letter = "" letter_number = {0}/></div>)
        }



        if (window.performance) {
            this.setLocalStorageRows()

            this.row_words = JSON.parse(localStorage.getItem("words")) === null ? [] : JSON.parse(localStorage.getItem("words"))
            this.row_number = JSON.parse(localStorage.getItem("row_number")) === null ? 0 : JSON.parse(localStorage.getItem("row_number"))
            this.correct_word = JSON.parse(localStorage.getItem("correct_word")) === null ? false : JSON.parse(localStorage.getItem("correct_word"))
            this.letter_number = JSON.parse(localStorage.getItem("letter_number")) === null ? 0 : JSON.parse(localStorage.getItem("letter_number"))
            this.number_of_guesses = JSON.parse(localStorage.getItem("number_of_guesses")) === null ? this.number_of_guesses : JSON.parse(localStorage.getItem("number_of_guesses"))
            this.result_array = JSON.parse(localStorage.getItem("result_array")) === null ? [[]] : JSON.parse(localStorage.getItem("result_array"))
            this.game_over = JSON.parse(localStorage.getItem("game_over")) === null ? "hide" : JSON.parse(localStorage.getItem("game_over"))
            this.count_keyb = JSON.parse(localStorage.getItem("count_keyb")) === null ? this.count_keyb : JSON.parse(localStorage.getItem("count_keyb"))
            this.master_guess_list = JSON.parse(localStorage.getItem("master_guess_list")) === null ? this.master_guess_list : JSON.parse(localStorage.getItem("master_guess_list"))

        }

        this.wrong = <p>&#8203;</p>
        this.right = <div/>



    }

    componentCleanup() {

        localStorage.setItem("row0finalword", JSON.stringify(this.rows[0].props.children.props.validation_array.length === 0 ? "" : this.rows[0].props.children.props.final_word));
        localStorage.setItem("row0validationarray", JSON.stringify(this.rows[0].props.children.props.validation_array));
        localStorage.setItem("row0latestletter", JSON.stringify(""));
        localStorage.setItem("row0letternumber", JSON.stringify(0));

        localStorage.setItem("row1finalword", JSON.stringify(this.rows[1].props.children.props.validation_array.length === 0 ? "" : this.rows[1].props.children.props.final_word));
        localStorage.setItem("row1validationarray", JSON.stringify(this.rows[1].props.children.props.validation_array));
        localStorage.setItem("row1latestletter", JSON.stringify(""));
        localStorage.setItem("row1letternumber", JSON.stringify(0));

        localStorage.setItem("row2finalword", JSON.stringify(this.rows[2].props.children.props.validation_array.length === 0 ? "" : this.rows[2].props.children.props.final_word));
        localStorage.setItem("row2validationarray", JSON.stringify(this.rows[2].props.children.props.validation_array));
        localStorage.setItem("row2latestletter", JSON.stringify(""));
        localStorage.setItem("row2letternumber", JSON.stringify(0));

        localStorage.setItem("row3finalword", JSON.stringify(this.rows[3].props.children.props.validation_array.length === 0 ? "" : this.rows[3].props.children.props.final_word));
        localStorage.setItem("row3validationarray", JSON.stringify(this.rows[3].props.children.props.validation_array));
        localStorage.setItem("row3latestletter", JSON.stringify(""));
        localStorage.setItem("row3letternumber", JSON.stringify(0));

        localStorage.setItem("row4finalword", JSON.stringify(this.rows[4].props.children.props.validation_array.length === 0 ? "" : this.rows[4].props.children.props.final_word));
        localStorage.setItem("row4validationarray", JSON.stringify(this.rows[4].props.children.props.validation_array));
        localStorage.setItem("row4latestletter", JSON.stringify(""));
        localStorage.setItem("row4letternumber", JSON.stringify(0));

        localStorage.setItem("row5finalword", JSON.stringify(this.rows[5].props.children.props.validation_array.length === 0 ? "" : this.rows[5].props.children.props.final_word));
        localStorage.setItem("row5validationarray", JSON.stringify(this.rows[5].props.children.props.validation_array));
        localStorage.setItem("row5latestletter", JSON.stringify(this.rows[5].props.children.props.latest_letter));
        localStorage.setItem("row5letternumber", JSON.stringify(0));

        localStorage.setItem("keyboard_dict", JSON.stringify(this.props.letter_dict))

        localStorage.setItem("row_number", JSON.stringify(this.row_number))
        localStorage.setItem("letter_number", JSON.stringify(0))
        localStorage.setItem("words", JSON.stringify(this.row_words))
        localStorage.setItem("correct_word", JSON.stringify(this.correct_word))

        localStorage.setItem("number_of_guesses", JSON.stringify(this.number_of_guesses))
        localStorage.setItem("result_array", JSON.stringify(this.result_array))
        localStorage.setItem("game_over", JSON.stringify(this.game_over))

        localStorage.setItem("count_keyb", JSON.stringify(this.count_keyb))
        localStorage.setItem("master_guess_list", JSON.stringify(this.master_guess_list))


    }

    emptyLocalStorage() {
        localStorage.setItem("row0finalword", JSON.stringify(""));
        localStorage.setItem("row0validationarray", JSON.stringify([]));
        localStorage.setItem("row0latestletter", JSON.stringify(""));
        localStorage.setItem("row0letternumber", JSON.stringify(0));


        localStorage.setItem("row1finalword", JSON.stringify(""));
        localStorage.setItem("row1validationarray", JSON.stringify([]));
        localStorage.setItem("row1latestletter", JSON.stringify(""));
        localStorage.setItem("row1letternumber", JSON.stringify(0));

        localStorage.setItem("row2finalword", JSON.stringify(""));
        localStorage.setItem("row2validationarray", JSON.stringify([]));
        localStorage.setItem("row2latestletter", JSON.stringify(""));
        localStorage.setItem("row2letternumber", JSON.stringify(0));

        localStorage.setItem("row3finalword", JSON.stringify(""));
        localStorage.setItem("row3validationarray", JSON.stringify([]));
        localStorage.setItem("row3latestletter", JSON.stringify(""));
        localStorage.setItem("row3letternumber", JSON.stringify(0));

        localStorage.setItem("row4finalword", JSON.stringify(""));
        localStorage.setItem("row4validationarray", JSON.stringify([]));
        localStorage.setItem("row4latestletter", JSON.stringify(""));
        localStorage.setItem("row4letternumber", JSON.stringify(0));

        localStorage.setItem("row5finalword", JSON.stringify(""));
        localStorage.setItem("row5validationarray", JSON.stringify([]));
        localStorage.setItem("row5latestletter", JSON.stringify(""));
        localStorage.setItem("row5letternumber", JSON.stringify(0));


        localStorage.setItem("row_number", JSON.stringify(0))
        localStorage.setItem("letter_number", JSON.stringify(0))
        localStorage.setItem("words", JSON.stringify([]))
        localStorage.setItem("correct_word", JSON.stringify(false))
        localStorage.setItem("result_array", JSON.stringify([[]]))
        localStorage.setItem("game_over", JSON.stringify(false))
        localStorage.setItem("keyboard_dict", JSON.stringify({
            "q":-1,
            "w":-1,
            "e":-1,
            "r":-1,
            "t":-1,
            "y":-1,
            "u":-1,
            "i":-1,
            "o":-1,
            "p":-1,
            "a":-1,
            "s":-1,
            "d":-1,
            "f":-1,
            "g":-1,
            "h":-1,
            "j":-1,
            "k":-1,
            "l":-1,
            "z":-1,
            "x":-1,
            "c":-1,
            "v":-1,
            "b":-1,
            "n":-1,
            "m":-1
        }))

    }

    setLocalStorageRows() {
        this.rows[0] = <div><Row validation_array={JSON.parse(localStorage.getItem("row0validationarray"))}
                                 final_word={JSON.parse(localStorage.getItem("row0finalword"))}
                                 latest_letter={JSON.parse(localStorage.getItem("row0latestletter"))}
                                 letter_number={JSON.parse(localStorage.getItem("row0letternumber"))}></Row></div>
        this.rows[1] = <div><Row validation_array={JSON.parse(localStorage.getItem("row1validationarray"))}
                                 final_word={JSON.parse(localStorage.getItem("row1finalword"))}
                                 latest_letter={JSON.parse(localStorage.getItem("row1latestletter"))}
                                 letter_number={JSON.parse(localStorage.getItem("row1letternumber"))}></Row></div>
        this.rows[2] = <div><Row validation_array={JSON.parse(localStorage.getItem("row2validationarray"))}
                                 final_word={JSON.parse(localStorage.getItem("row2finalword"))}
                                 latest_letter={JSON.parse(localStorage.getItem("row2latestletter"))}
                                 letter_number={JSON.parse(localStorage.getItem("row2letternumber"))}></Row></div>
        this.rows[3] = <div><Row validation_array={JSON.parse(localStorage.getItem("row3validationarray"))}
                                 final_word={JSON.parse(localStorage.getItem("row3finalword"))}
                                 latest_letter={JSON.parse(localStorage.getItem("row3latestletter"))}
                                 letter_number={JSON.parse(localStorage.getItem("row3letternumber"))}></Row></div>
        this.rows[4] = <div><Row validation_array={JSON.parse(localStorage.getItem("row4validationarray"))}
                                 final_word={JSON.parse(localStorage.getItem("row4finalword"))}
                                 latest_letter={JSON.parse(localStorage.getItem("row4latestletter"))}
                                 letter_number={JSON.parse(localStorage.getItem("row4letternumber"))}></Row></div>
        this.rows[5] = <div><Row validation_array={JSON.parse(localStorage.getItem("row5validationarray"))}
                                 final_word={JSON.parse(localStorage.getItem("row5finalword"))}
                                 latest_letter={JSON.parse(localStorage.getItem("row5latestletter"))}
                                 letter_number={JSON.parse(localStorage.getItem("row5letternumber"))}></Row></div>
    }


    componentDidMount() {
        const firstTime = localStorage.getItem("isFirstTime")
        if(firstTime !== null) {
            this.audio.pause()
        } else {
            this.emptyLocalStorage()

            localStorage.setItem("isFirstTime", 'true')
            window.location.reload()
        }
        const updated_dict = JSON.parse(localStorage.getItem(("keyboard_dict"))) === null ? this.default_keyb : JSON.parse(localStorage.getItem("keyboard_dict"))

        for (const letter in this.props.letter_dict) {
            this.props.letter_dict[letter] = updated_dict[letter]
        }

        window.addEventListener('beforeunload', this.componentCleanup);
    }

    componentWillUnmount() {
        this.componentCleanup()
        window.removeEventListener('beforeunload', this.componentCleanup);
    }





    render() {




        const checkWord = async (word) => {
            word = word.toLowerCase()
            const response = await fetch(`http://localhost:3000/word?query=${word}`, {mode:'cors'});

            // Check for a valid HTTP response
            if(response.ok !== true) {
                throw new Error(response.statusText);
            }

            //Retrieve the todo list items
            const results = await response.json();
            if(results.status === "error") {
                throw new Error(results.message);
            }

            this.results = results
            if (this.letter_number === 5) {
                this.correct_word = (results[0] === 2 && results[1] === 2 && results[2] === 2 && results[3] === 2 && results[4] === 2)
                this.word_exists = !(results[0] === -1)
                this.result_array[this.row_number] = (results)
            }
        }

        const getWord = async () => {

            const response = await fetch(`http://localhost:3000/getWord`, {mode:'cors'});
            this.final_word = await response.text()

        }

        String.prototype.replaceAt = function(index, replacement) {
            return this.substring(0, index) + replacement + this.substring(index + replacement.length);
        }


        if ((!this.animating && (this.props.keys === "backspace" || this.props.keys === "del")) && this.letter_number >= 1 && this.row_number < 6) {

            this.letter_number -= 1
            this.word = this.word.slice(0, this.letter_number)
            this.correct_word = false
            this.row_words[this.row_number] = (this.word)

            this.rows[this.row_number] = <div>
                <Row validation_array = {[]} latest_letter={""} letter_number={this.letter_number} final_word = {this.row_words[this.row_number]}/></div>


        } else if (!this.animating && this.props.keys.match(/^[a-zA-Z]+$/) && this.props.keys.length === 1 && this.letter_number <= 4 && !this.correct_word && this.row_number < 6) {

            this.word = this.word.replaceAt(this.letter_number, this.props.keys)
            this.count_keyb[this.props.keys] += 1
            this.letter_number += 1
            checkWord(this.word)
            getWord()
            this.row_words[this.row_number] = (this.word)
            this.rows[this.row_number] = <div>
                <Row validation_array = {[]} latest_letter={this.props.keys} letter_number={this.letter_number} final_word = {this.row_words[this.row_number]}/></div>


        } else if (this.props.keys === "enter" && this.word.length === 5 && this.row_number < 6) {



            this.row_words[this.row_number] = (this.word)
            if (this.correct_word) {

                if (this.row_words[this.row_number] in this.master_guess_list) {
                    this.master_guess_list[this.row_words[this.row_number]] += 1
                } else {
                    this.master_guess_list[this.row_words[this.row_number]] = 1
                }

                this.game_over = "overlay-right"


                this.number_of_guesses[this.row_number + 1] += 1

                this.wrong = <div/>;



                this.right = <text className="font-weight-bold text-success">Guessed Correctly</text>;

                this.validation_array = this.results
                for (let i = 0; i < this.row_words[this.row_number].length; i++) {
                    if (this.props.letter_dict[(this.row_words[this.row_number])[i]] < this.validation_array[i]) {
                        this.props.letter_dict[(this.row_words[this.row_number])[i]] = this.validation_array[i]
                    }
                }

                if (this.row_number <= 5) {
                    this.rows[this.row_number] = <div>
                        <Row validation_array = {this.validation_array} latest_letter={this.props.keys} letter_number={this.letter_number} final_word = {this.row_words[this.row_number]}/></div>
                    this.row_number += 1
                    this.word = ""
                    this.letter_number = 0
                }
            } else {

                if (this.word_exists) {

                    if (this.row_words[this.row_number] in this.master_guess_list) {

                        this.master_guess_list[this.row_words[this.row_number]] += 1
                    } else {
                        this.master_guess_list[this.row_words[this.row_number]] = 1
                    }

                    if (this.row_number === 5) {

                        this.game_over = "overlay-wrong"

                    }

                    this.wrong = <text className="font-weight-bold text-danger">Guessed Incorrectly</text>;
                    this.right = <div/>;
                    this.validation_array = this.results
                    for (let i = 0; i < this.row_words[this.row_number].length; i++) {
                        if (this.props.letter_dict[(this.row_words[this.row_number])[i]] < this.validation_array[i]) {
                            this.props.letter_dict[(this.row_words[this.row_number])[i]] = this.validation_array[i]
                        }
                    }

                    this.rows[this.row_number] =
                        <div className="" onAnimationStart={() => this.animating = true} onAnimationEnd={() => {

                            this.animating = false
                        }}><Row validation_array={this.validation_array}
                                latest_letter={this.props.keys} letter_number={this.letter_number}
                                final_word={this.row_words[this.row_number]}/></div>
                    if (this.row_number < 6) {
                        this.row_number += 1
                        this.word = ""
                        this.letter_number = 0
                    }
                } else {
                    if(this.correct_word === true) {
                        this.wrong = <div/>;
                    } else {
                        this.wrong = <text className="font-weight-bold text-danger">Not in dictionary</text>;
                        if (this.word.toLowerCase() === "yoshi") {
                            this.yoshi.play()
                        } else if (this.word.toLowerCase() === "mario"){
                            this.mario.play()
                        } else if (this.word.toLowerCase() === "luigi"){
                            this.luigi.play()
                        } else if (this.word.toLowerCase() === "wario"){
                            this.wario.play()
                        }
                        else {
                            this.audio.play();
                        }
                    }
                    if(this.correct_word === false) {
                        this.right = <div/>;
                    } else {
                        this.right = <text className="font-weight-bold text-success">Guessed Correctly</text>;
                    }
                    this.rows[this.row_number] =
                        <div className="shake" onAnimationStart={() => this.animating = true} onAnimationEnd={() => {
                            this.animating = false
                        }}><Row validation_array = {[]}
                                latest_letter={this.props.keys} letter_number={this.letter_number}
                                final_word={this.row_words[this.row_number]}/></div>

                }
            }
        } else if (this.props.keys === "enter" && this.word.length !== 5 && this.row_number < 6 && !this.correct_word) {
            this.row_words[this.row_number] = (this.word)
            this.rows[this.row_number] = <div className="shake">
                <Row validation_array = {[]} latest_letter={""} letter_number={this.letter_number} final_word = {this.row_words[this.row_number]}/></div>
            this.wrong = <div className={"font-weight-bold text-danger"}>Enter 5 letters</div>
            if (this.word.toLowerCase() === "toad") {
                this.toad.play()
            } else {
                this.audio.play()
            }
        }

        const restart_game = () => {
            this.emptyLocalStorage()
            localStorage.setItem("game_over", JSON.stringify(false))
            localStorage.setItem("keyboard_dict", JSON.stringify(this.default_keyb))
            localStorage.setItem("result_array", JSON.stringify(this.result_array))


            this.setLocalStorageRows()


            const updated_dict = JSON.parse(localStorage.getItem(("keyboard_dict")))

            for (const letter in this.props.letter_dict) {
                this.props.letter_dict[letter] = updated_dict[letter]
            }

            ;
            this.props.rerender();


            fetch(`http://localhost:3000/reset`, {mode: 'cors'});



            this.props.refocus();
            this.row_words = []
            this.row_number = 0
            this.correct_word = false
            this.letter_number = 0
            this.word = ""
            this.game_over = "hide"

            this.props.calledRerender["h"] = false;

            this.wrong = <div/>
            this.right = <div/>
            this.audio.pause()
            this.yoshi.pause()
            this.mario.pause()
            this.luigi.pause()
            this.wario.pause()

        }

        if (this.props.calledRerender["h"]) {

            restart_game()

        }


        return (

            <>
                <GameOver reset = {restart_game} row_number = {this.row_number} master_guess_list = {this.master_guess_list} count = {this.count_keyb} word = {this.final_word} game_over={this.game_over} words={this.row_words} frequency = {this.number_of_guesses} colors = {this.result_array}/>
                <div>
                    <br/>
                    {this.right}
                    {this.wrong}
                    <div id="game-board">
                        {this.rows}
                    </div>
                </div>
            </>

        )
    }
}
export default Board