import React from "react";
class Letters extends React.Component {
    render() {
        return (
            <div className={this.props.validation}>
                {this.props.latest_letter}
            </div>
        )
    }
}

export default Letters;