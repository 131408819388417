import React from 'react'
class Keyboard extends React.Component {

    letters = ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "a", "s", "d", "f", "g", "h", "j", "k", "l", "z", "x", "c", "v", "b", "n", "m"]

    keyboard_buttons = []




    render() {

        const update = (event) => {
            if (event.target.id.includes("enter")) {
                this.props.update("enter")
            } else if (event.target.id.includes("backspace")) {
                this.props.update("backspace")
            } else {
                this.props.update(event.target.innerText)

            }
        }

        this.keyboard_buttons = []
        for (let i = 0; i < this.letters.length; i++) {
            if(this.props.letter_dict[this.letters[i]] === -1) {
                this.keyboard_buttons.push(<button className="keyboard-button keyboard-normal" onClick={update}>{this.letters[i]}</button> )
            } else if(this.props.letter_dict[this.letters[i]] === 0) {
                this.keyboard_buttons.push(<button className="keyboard-button keyboard-incorrect"  onClick={update}>{this.letters[i]}</button> )
            } else if(this.props.letter_dict[this.letters[i]] === 1) {
                this.keyboard_buttons.push(<button className="keyboard-button keyboard-yellow" onClick={update}>{this.letters[i]}</button> )
            } else if(this.props.letter_dict[this.letters[i]] === 2) {
                this.keyboard_buttons.push(<button className="keyboard-button keyboard-green" onClick={update}>{this.letters[i]}</button> )
            }
        }

        return (
            <div id="keyboard-cont">
                <div className="first-row">
                    {this.keyboard_buttons.slice(0, 10)}
                </div>
                <div className="second-row">
                    {this.keyboard_buttons.slice(10, 19)}
                </div>
                <div className="third-row">
                    <button className="keyboard-button keyboard-normal backspace" id="backspace" onClick={update}>
                        <img src="w98_trust1_restrict-1.png" id="backspace" height={"60%"}/>
                    </button>
                    {this.keyboard_buttons.slice(19)}
                    <button className="keyboard-button keyboard-normal enter" id="enter" onClick={update}>
                        <img src="w98_trust0-1.png" height={"60%"} id="enter"/>
                        </button>
                </div>
            </div>
        );
    }
}

export default Keyboard;