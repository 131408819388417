import Letters from "./Letters";
import React from "react";
class Row extends React.Component {

    constructor() {
        super();
        this.state = {
            letter_array : [],
        }

    }


    componentDidMount() {
        const letters = []
        for (let i = 0; i < 5; i++) {
            letters.push(<Letters validation={"letter letter-normal"}/>)
        }

        this.setState({letter_array: letters})

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const letters = []
        for (let i = 0; i < 5; i++) {
            letters.push(<Letters validation={"letter letter-normal"}/>)
        }

        this.state.letter_array =  letters


    }




    render() {
        let validation_string = ""

        if (this.props.final_word === null || this.props.final_word === undefined || this.props.final_word.length === 0) {
            this.state.letter_array[this.props.letter_number] = <Letters validation = {"letter letter-normal"} latest_letter={this.props.latest_letter}/>
        } else {
            for (let i = 0; i < this.props.final_word.length; i++) {
                if (this.props.validation_array !== undefined) {
                    if (this.props.validation_array[i] === 0) {
                        validation_string = "letter letter-incorrect"
                    } else if (this.props.validation_array[i] === 1) {
                        validation_string = "letter letter-yellow"
                    } else if (this.props.validation_array[i] === 2) {
                        validation_string = "letter letter-green"
                    }
                }
                this.state.letter_array[i] = <Letters validation = {validation_string === "" ? "letter letter-normal" : validation_string} latest_letter={this.props.final_word[i]}/>
            }
        }
        return (
            <div className="row">
                {this.state.letter_array}
            </div>
        );
    }
}
export default Row;