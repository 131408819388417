import logo from './wordlegame.png';
import React from 'react'
import NavBar from "./NavBar";



//import ReactCSSTransitionGroup from 'react-transition-group'; // ES6


import './App.css';
import TextField from "./TextField";
import Board from "./Board";
import Keyboard from "./Keyboard";



class App extends React.Component{
    constructor() {
        super(arguments);
        this.appDiv = React.createRef();
        this.state = {
            guessedWord: false,
            letters: [],
            letter_number: 0,
            row_number: 0,
            row_words: [],
            enterable: false,
            shake: false,
            current_key: "",
            letter_dict : {
                "q":-1,
                "w":-1,
                "e":-1,
                "r":-1,
                "t":-1,
                "y":-1,
                "u":-1,
                "i":-1,
                "o":-1,
                "p":-1,
                "a":-1,
                "s":-1,
                "d":-1,
                "f":-1,
                "g":-1,
                "h":-1,
                "j":-1,
                "k":-1,
                "l":-1,
                "z":-1,
                "x":-1,
                "c":-1,
                "v":-1,
                "b":-1,
                "n":-1,
                "m":-1
            },
            rerender: {"h": false},
            hints:<p style={{color: "#3a3a3b"}}>&#8203;</p>
        }
    }





    async componentDidMount() {
        try {
            this.appDiv.current.focus();
            const wordGuessed = await (getWord());
            this.setState({guessedWord: wordGuessed})
        } catch (error) {
            console.error(error);
        }
    }

    render() {




        const update = (string) => {


            this.setState({current_key: string.toLowerCase()})

        }

        const key_entered = (event) => {
                update(event.key)

        }

        const divStyle = {
            position: 'absolute',
            zIndex: 0,
            top: '0px',
            left: '0px'
        };

        const rerender = () => {
            this.setState({hints: <p style={{color: "#3a3a3b"}}>&#8203;</p>} )
            this.state.current_key = ""
            this.forceUpdate();
        }

        const refocus = () => {
            this.appDiv.current.focus();
        }

        const reset = () => {
            this.setState({hints: <p style={{color: "#3a3a3b"}}>&#8203;</p>} )
            this.setState({rerender: {"h":true}})
        }

        const getHint = async () => {
            const response = await fetch("http://localhost:3000/getWordHint", {mode: "cors"});

            return await response.json();

        }

        const displayHint = async () => {
            getHint().then((response) => {
                console.log(response);
                let output_string = "";
                var res = response.filter(elements => {
                    return elements !== null;
                });
                for (let i = 0; i < res.length; i++) {

                        if (i === res.length - 1) {
                            output_string += res[i];
                        } else {
                            output_string += res[i] + ", ";
                        }

                }
                this.state.current_key = ""
                refocus()
                this.setState({hints: <p style={{color: "#3a3a3b"}}>[{output_string}]</p>})

            })

        }
        


  return (

      <div>
          <NavBar style = {divStyle} reset = {reset}/>
      <div className="App " onKeyDown={key_entered} ref={this.appDiv} tabIndex="0">
        <header className="App-header disable-scrolling">
            <Board calledRerender = {this.state.rerender} refocus = {refocus} rerender = {rerender} letter_dict = {this.state.letter_dict} latest_letter = {this.state.latest_letter} row_words = {this.state.row_words} letter_number = {this.state.letter_number} guessedWord = {this.state.guessedWord} keys = {this.state.current_key} shake = {this.state.shake} />
            <br/>
            <button onClick={displayHint} className={"hint"}>Hint</button>
            {this.state.hints}

            <Keyboard update = {update} letter_dict = {this.state.letter_dict}/>

            {/*<TextField verify = {checkWord}/>*/}
        </header>
      </div>
      </div>
  );
}
}

const getSomething = "http://localhost:3000/word?query=hi"

const getWord = async () => {

    // Request the todo list items
    const response = await fetch(getSomething, {mode:'cors'});

    // Check for a valid HTTP response
    if(response.ok !== true) {
        throw new Error(response.statusText);
    }

    //Retrieve the todo list items
    const results = await response.json();
    if(results.status === "error") {
        throw new Error(results.message);
    }

    //Return the results
    return results;
};

export default App;