import React from "react";

class GameOver extends React.Component {
    render() {
        const emoji = []
        const word = ""
        const p_list = [];
        const word_list = [];
        const count_list = [];
        let guess_list = [];
        // Create items array
        let sortable = [];
        for (var count in this.props.count) {
            sortable.push([count, this.props.count[count]]);
        }

        sortable.sort(function (a, b) {
            return b[1] - a[1];
        });

        for (let key in this.props.frequency) {
            let p_string = "";
            for (let i = 0; i < Math.floor((this.props.frequency[key] / Math.max.apply(null, Object.values(this.props.frequency))) * 10); i++) {
                p_string += "*";
            }
            while (p_string.length < 10) {
                p_string += "-";
            }
            p_list.push(<p className="overlay-stats">{key + ": [" + p_string}] {this.props.frequency[key]}</p>)
        }


        for (let i = 0; i < 6; i++) {
            count_list.push(<p className="overlay-stats">{sortable[i][0] + ": " + sortable[i][1]}</p>)
        }

        let sortable_guess = [];
        for (var guess in this.props.master_guess_list) {
            sortable_guess.push([guess, this.props.master_guess_list[guess]]);
        }

        sortable_guess.sort(function (a, b) {
            return b[1] - a[1];
        });

        for (let i = 0; i < sortable_guess.length; i++) {
            guess_list.push(<p className="overlay-stats">{sortable_guess[i][0] + ": " + sortable_guess[i][1]}</p>)
        }
        guess_list = guess_list.slice(0, 6)


        let counter = 0
        let emoji_string = "";

        for (let word in this.props.words) {
            if (this.props.colors[counter] !== undefined) {

                let w_string = []
                emoji_string += "\n"
                for (let i = 0; i < this.props.words[word].length; i++) {
                    if (this.props.colors[counter][i] === 0) {
                        emoji_string += "⬛"
                        w_string.push(<span style={{background: "#3a3a3b"}}>{this.props.words[word].charAt(i)}</span>);
                    } else if (this.props.colors[counter][i] === 1) {
                        emoji_string += "🟨"
                        w_string.push(<span
                            style={{background: "goldenrod"}}>{this.props.words[word].charAt(i)}</span>);
                    } else if (this.props.colors[counter][i] === 2) {
                        emoji_string += "🟩"
                        w_string.push(<span
                            style={{background: "forestgreen"}}>{this.props.words[word].charAt(i)}</span>);
                    }
                }

                counter += 1

                word_list.push(<p
                    className="overlay-stats">[{w_string[0]} {w_string[1]} {w_string[2]} {w_string[3]} {w_string[4]}]</p>)
            }
        }

        const emojis = () => {
            navigator.clipboard.writeText(`not_wordle ${this.props.game_over === "overlay-wrong" ? "X/6" : `${this.props.row_number}/6`} \n` + emoji_string).then(r => alert("Copied to clipboard!"))

        }

        return (
            <>
                <div className={this.props.game_over === "overlay-wrong" ? "overlay-wrong" : "hide"}>
                    <h1 className="overlay-image">
                        <mark>not_wordle</mark>
                    </h1>
                    <p className="overlay-text">You ran out of guesses and need to restart</p>
                    <br/>
                    <p className="overlay-text"> Here are your stats: </p>
                    <br/>
                    <div style={{width: "100%", marginLeft: "10%"}}>
                        <div style={{width: "25%", height: "100px", float: "left"}}>
                            {p_list}
                        </div>
                        <div style={{width: "25%", marginLeft: "30%", height: "100px"}} onClick={emojis}>
                            {word_list}
                        </div>
                        <div style={{width: "25%", marginLeft: "50%", height: "27px"}}>
                            {count_list}
                        </div>
                        <div style={{width: "25%", marginLeft: "70%", height: "5px"}}>
                            {guess_list}
                        </div>


                    </div>
                    <h1 className="overlay-word">The correct word was: {this.props.word}</h1>
                    <button style={{background: "transparent", border: "none", color: "white"}} onClick={emojis} className="overlay-footer">[<span style={{color: "green"}}>s</span><span style={{color: "goldenrod"}}>h</span><span style={{color: "lightgray"}}>a</span><span style={{color: "forestgreen"}}>r</span><span style={{color: "goldenrod"}}>e</span>]</button>
                    <br/>
                    <button style={{background: "transparent", border: "none", color: "white"}} onClick={this.props.reset} className="overlay-footer">press X to restart</button>

                </div>

                <div className={this.props.game_over === "overlay-right" ? "overlay-right" : "hide"}>
                    <h1 className="overlay-image">
                        <mark className="success">not_wordle</mark>
                    </h1>
                    <p className="overlay-text">You guessed correctly</p>
                    <br/>
                    <p className="overlay-text"> Here are your stats: </p>
                    <br/>
                    <div style={{width: "100%", marginLeft: "10%"}}>
                        <div style={{width: "25%", height: "100px", float: "left"}}>
                            {p_list}
                        </div>
                        <div style={{width: "25%", marginLeft: "30%", height: "100px"}} onClick={emojis}>
                            {word_list}
                        </div>
                        <div style={{width: "25%", marginLeft: "50%", height: "27px"}}>
                            {count_list}
                        </div>
                        <div style={{width: "25%", marginLeft: "70%", height: "5px"}}>
                            {guess_list}
                        </div>
                    </div>
                    <button style={{background: "transparent", border: "none", color: "white"}} onClick={emojis} className="overlay-footer">[<span style={{color: "forestgreen"}}>s</span><span style={{color: "goldenrod"}}>h</span><span style={{color: "darkgray"}}>a</span><span style={{color: "forestgreen"}}>r</span><span style={{color: "goldenrod"}}>e</span>]</button>
                    <br/>
                    <button style={{background: "transparent", border: "none", color: "white"}} onClick={this.props.reset} className="overlay-footer">press X to restart</button>
                </div>
            </>
        )
    }
}

export default GameOver;